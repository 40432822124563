<template>
  <div
    :id="`wheel-container-${randomIdRoulette}`"
    class="wheel-container"
  >
    <div
      class="wheel"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        transitionDuration: `${duration}s`,
        transform: `rotate(0deg)`,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="item.id"
        class="wheel-item"
        :style="{
          transform: `rotate(${itemAngle * index}deg) skewY(${-(90 - itemAngle)}deg)`,
          background: item.background,
        }"
      >
        <div
          class="content"
          :style="{
            transform: `skewY(${90 - itemAngle}deg) rotate(${itemAngle / 2}deg)`,
          }"
        >
          <span
            :style="{ color: item.textColor }"
            v-html="item.htmlContent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RouletteTable',
  emits: ['wheelStart', 'wheelEnd'],
  props: {
    items: {
      type: Array,
      required: true,
      validator(value) {
        return value.length >= 4;
      },
    },
    size: {
      type: Number,
      required: false,
      default: 300,
    },
    duration: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      randomIdRoulette: 0,
      wheelResult: null,
      processingLock: false,
    };
  },
  computed: {
    itemAngle() {
      return 360 / this.items.length;
    },
  },
  mounted() {
    this.randomIdRoulette = Math.floor(Math.random() * 1000000);
    this.$nextTick(() => {
      const wheelElement = document.querySelector(
        `#wheel-container-${this.randomIdRoulette} .wheel`
      );
      if (wheelElement) {
        wheelElement.addEventListener('transitionend', this.onSpinComplete);
      } else {
        console.error('Wheel element not found in mounted hook');
      }
    });
  },
  methods: {
    launchWheel() {
      if (this.processingLock || this.items.length === 0) {
        console.warn('Wheel is locked or has no items');
        return;
      }
      this.processingLock = true;
      this.wheelResult = Math.floor(Math.random() * this.items.length);
      const wheelElt = document.querySelector(
        `#wheel-container-${this.randomIdRoulette} .wheel`
      );
      
      if (!wheelElt) {
        console.error('Wheel element not found');
        this.processingLock = false;
        return;
      }

      const fullRotations = Math.floor(Math.random() * 4) + 2;
      const finalAngle = -(fullRotations * 360 + this.wheelResult * this.itemAngle + this.itemAngle / 2);

      wheelElt.style.transform = `rotate(${finalAngle}deg)`;
      this.$emit('wheelStart');
    },
    onSpinComplete() {
      this.processingLock = false;
      if (this.wheelResult !== null && this.items[this.wheelResult]) {
        this.$emit('wheelEnd', this.items[this.wheelResult]);
      } else {
        console.error('Invalid wheel result:', this.wheelResult);
        this.$emit('wheelEnd', null);
      }
    },
  },
  beforeUnmount() {
    const wheelElement = document.querySelector(
      `#wheel-container-${this.randomIdRoulette} .wheel`
    );
    if (wheelElement) {
      wheelElement.removeEventListener('transitionend', this.onSpinComplete);
    }
  },
};
</script>

<style lang="scss" scoped>
.wheel-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
}
.wheel {
  background: white;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  transition: transform cubic-bezier(0.25, 0.1, 0.25, 1);
}
.wheel-item {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  
  .content {
    position: absolute;
    left: -100%;
    width: 200%;
    height: 200%;
    text-align: center;
    padding-top: 20px;
  }
}
</style>
